body.single-video{

  .c-text{
    p:first-of-type{
      font-weight: 600;
      font-size: 1.1rem;
    }
  }
  .right-layout{
    @include bp-medium {
      border-left: solid 1px $color-orange;
    }
  }

  .video-text-content{
    margin-bottom: 4rem;
  }

  .categories{
    list-style: none;
    margin-left: 0;
    @include bp-medium {

      margin-left: 1rem;

    }
    li{
      a{
        @include bp-medium{
          padding: 0;
          border: none;
          text-align: left;
        }
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
  .roles{
    text-align: center;
    ul{
      list-style: none;
      margin-left: 0;
      //border-bottom: solid 1px $color-orange;

    }
    li{
      text-transform: uppercase;
      display: inline-block;
      font-size: 0.8rem !important;

      span{
      }
      h3{
        font-size: 0.85rem;

        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
      }
    }
  }
  #section--single-video-player{
    //padding: 40px 0px;
    background-color:rgba(black,0.5);


  }

 #single-video-content{
   header{
     h1.title{
       margin-bottom: 0;
     }

     h4.description{
       font-weight:200;
       margin-top: 0;
     }
   }
 }
  #section--single-info{

    .links{
      display:flex;
      flex-wrap: wrap;
      //justify-content: flex-end;
      margin: -5px;
      .c-btn{
        margin: 5px;
      }
    }
  }

  .single-video-header{
    margin-bottom: 1.3rem;
    h1,h4{
      margin-bottom: 0;
    }
  }
}
@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon.eot?fmla6x');
  src:  url('../assets/fonts/icomoon.eot?fmla6x#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?fmla6x') format('truetype'),
    url('../assets/fonts/icomoon.woff?fmla6x') format('woff'),
    url('../assets/fonts/icomoon.svg?fmla6x#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\ea92";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-vimeo:before {
  content: "\eaa0";
}

.icon-imdb:before {
  content: "\e901";
}
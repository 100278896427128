.c-see-more-work{
  display: flex;
  justify-content: center;
  margin-top: -1rem;
  margin-bottom: 8rem;
  h3.desktop{

    .page-video-category & {
      display: none;
    }
    @media screen and (max-width: $bp-small) {
      display: none;
    }
    border: solid 1px $color-orange;
    width: auto;
    padding: 0.3rem 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: 1rem;
    a{
      font-weight: 600;
      // font-size: 1.4rem;
      margin-left:0.3rem;
      margin-right: 0.3rem;
      margin-bottom: 0.05rem;
    }
  }

  h3.mobile{
    margin-top: 2rem;
    display: flex;

    @media screen and (min-width: $bp-small) {
      display: none;
    }
    flex-direction: column;
    align-items: center;

    .page-video-category &{
      .current{
        display: none;
      }
    ;
    }
  }

}
/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  //border-bottom: 1px solid $color-border;
  margin-bottom: 1.5em;
  //padding-bottom: 1em;
  text-transform: uppercase;
  font-size: $h3-font-size;

  .areas{
    a{
      text-decoration: none;
      color: white;
      &:hover{
        color: $color-orange;
      }
    }
    h3{
      margin-top: 5px;
      margin-bottom: 10px;
      display: inline-block;
      font-weight: 300;

    }
    span{
      @extend .h5;
      color:white;
      @extend strong;
      font-weight: 500 !important;
    }
  }
}

a.home-link{
  text-decoration: none;
 color:$color-orange;
}

h5{
  //text-align: right;
  font-size: 13px;
  font-weight: 300;
  span{
    color:$color-orange;
  }
}

.header-wrapper{
  margin-left: -1rem;
  margin-right: -1rem;
  .header-left,.header-right{
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .header-right{
    @media screen and (max-width: $bp-medium) {
      display: none;
    }
  }
}

.email{
  font-weight: 600;
  font-size: 1rem;
  text-transform: none;
}


.social{
  display: flex;
  justify-content: flex-end;
  a{
    text-decoration: none;
  }
}

.reel-link{
  font-weight: normal;
  text-decoration: none;
}
body.page-video-category{


  .tag-filters{
    margin-bottom: 2rem;
    text-align: center;

    button{
      margin-bottom: 3px;
      &:not(.mixitup-control-active){
        color: $color-medium-grey;
        border-color: $color-dark-grey;
      }
      &.mixitup-control-active{
        color: $color-orange;
      }

      &:focus{
        outline: none;
      }
    }
  }
}
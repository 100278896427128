button.hamburger{
  @media screen and (min-width: $bp-medium) {
    display: none;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  }
  position: fixed;
  top:0;
  right: 0;
  z-index: 101;
  &:focus{
    outline: none;
  }
  -webkit-tap-highlight-color: transparent;
  //opacity: 0;
}

.c-mobile-nav{
  visibility: hidden;
  padding: 1rem;
  background-color: black;
  position: fixed;

  z-index: 100;
  top:0;
  right: 0;
  bottom:0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.c-mobile-nav--links{
  list-style: none;
  padding: 0;
  margin-left: 0;
  margin-bottom: 5rem;


}


.c-mobile-nav--item{
  a{
    color: white;

  }
  &.active{
    a{
      color: $color-orange;

    }
  }
}
.c-mobile-nav--link{
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
}

.c-mobile-nav--email{
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.c-mobile-nav--social{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -0.2rem;
  a{
    margin: 0.2rem;
    text-transform: uppercase;
  }
}


.block-transition{

  .mobile-nav--opened &{
    .block-transition-item{
      opacity: 1;
      transform: scale(1);
    }
  }

  .block-transition-item{
    transition-property: transform,opacity;
    opacity: 0;
    transition-duration: 0.8s;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  }

  @for $i from 1 through 7{
    .block-transition-item:nth-child(#{$i})){
      transition-delay: #{(($i - 1) * 0.1) }s;
    }
  }

}
.c-video-thumbnail{
  -webkit-tap-highlight-color: transparent;

  a:active,:focus{
    h2,h3{
      color: $color-orange !important;

    }
  }
  &:hover{
    .video-text{
      box-shadow: 0 1px 0 $color-orange;
    }
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    transition: opacity 0.8s, transform 0.3s ease-out;
  }

  figure {
    position: relative;
    margin: 0;
    padding-top: 41%;
    height: 0;
    overflow: hidden;

    &.aspect-ratio-1x1{
      padding-top: 100%;
    }

    &.aspect-ratio-21x9{
      padding-top: 43%;
    }

    &.aspect-ratio-16x9{
      padding-top: 56.25%;
    }
  }

}

.c-video-thumbnail--bw{
  @media screen and (min-width:$bp-large){
    img{
      transition: transform 0.8s, filter 0.1s;
      filter: grayscale(100%);
    }

    &:hover{
      img{
        filter: grayscale(0);

      }
    }
  }

}

.c-video-thumbnail--opacity{
  @media screen and (min-width:$bp-large){
    img{
      transition: transform 0.8s, opacity 0.1s;
      opacity:0.9;
    }

    &:hover{
      img{
        opacity:1;
        //filter:grayscale(1);
      }
    }
  }
}

.c-video-thumbnail--overlay{

  &:hover{
    figure:after{
      opacity:0.1;
    }
  }

  figure:after{
     transition: opacity 0.2s;
      content:'';
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background: $color-orange;
      opacity: 0;

    &:hover{
      opacity:0.1;
    }
  }
}
.c-video-thumbnail--animate-scale {
  &:hover {
    figure::before {
      display: block;
    }

    img {
      transform: scale(1.01);
    }
  }
}
.c-video-thumbnail--animate-border {

  transition: box-shadow 0.1s;
  &:hover {

    //outline-bottom: solid 2px $color-orange;
    box-shadow: 0 1px 0 $color-orange;

  }
}


.c-video-thumbnail--default{
  width:25%;
  @media screen and (max-width: $bp-small){
    width:50%;
  }
}

.c-video-thumbnail--major{
  width:50%;
  @media screen and (max-width: $bp-small){
    width:100%;
  }
}

// used in a single video gallery
.c-video-thumbnail--small{
  border-radius: $global-radius;
  width: calc(100%/3);
  //@media screen and (max-width: $bp-small){
  //  width:33%;
  //}

  &.c-video-thumbnail--aspect-ratio-1x1{
    width:calc(100%/6);
    //@media screen and (max-width: $bp-small){
    //  width:calc(100%/3);
    //}
  }
  cursor:pointer;
}

.c-video-thumbnail--small-4{
  width:25%;
}
.c-video-thumbnail--small-6{
  width:calc(100%/6);
}


.thumbnail{
  position: relative;

    &:hover{
      .video-text{
        color:white;
      }
    }




}


.video-text-wrapper{
  position: absolute;
  width:100%;
  height: 100%;
  top:0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-text{
  transition: color 0.3s ease-out;
  color: rgba(white,0.8);
  text-transform: uppercase;
  h4{
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 0;
    text-shadow: 0 3px 6px rgba(0,0,0,0.53);
  }


  //border-bottom: solid 1px $color-orange;

  @media screen and (max-width: $bp-small) {
    h4{
      font-size: 1.1rem;
    }
  }
}



.c-video-thumbnail {


}

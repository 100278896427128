.u-embed{
  border-radius: $global-radius;
  position: relative;
  height: 0;
  overflow: hidden; max-width: 100%;

  &.u-embed--16x9{
    padding-bottom: 56.25%;
  }
  &.u-embed--21x9{
    padding-bottom: 41.85%;
  }

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
  }
}
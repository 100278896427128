/* ==========================================================================
   #NORMALIZE
   ========================================================================== */

@import 'normalize-scss/sass/normalize/import-now';


strong,b{
  font-weight: 600;
}

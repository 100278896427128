.c-video-embed{
  width:100%;
}

.c-video-embed-title{
  margin-bottom: $pad-small;
}


.c-instagram-embed{
  cursor: pointer;
  width:100%;
  display: flex;

  justify-content: center;

  video{
    width:100%;
    height:100%;
    max-width:640px;
    max-height: 640px;
  }

  .wrapper{
    position: relative;
    max-height: 640px;

  }
}



.c-youtube-embed {
  background-color: #000;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  iframe{
    border: none;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    left: 0;
    //opacity: 0.7;
    cursor: pointer;
    position: absolute;
  }

}

.play-button {

  background-color: #333;
  box-shadow: 0 0 30px rgba( 0,0,0,0.8 );
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d( -50%, -50%, 0 );
  &:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
  }
}


.c-contact-cta{
  margin-bottom: 6rem;
  h2{
    text-align: center;
    margin-bottom: -0.3rem;
    @media screen and (max-width: $bp-small){
      margin-bottom: 0rem;
    }
  }
  h3{
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
    color: $color-light-grey;
  }

  .cta{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1rem;
    span{
      font-size: 1.5rem;
      font-weight: 600;
      margin-left: 0.5rem;
      color: $color-orange;
    }
  }
}

/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */

$utility-spacing: 1em;

.u-margin {
  margin: $utility-spacing;
}

.u-margin-top {
  margin-top: $utility-spacing;
}
.u-margin-top--2 {
  margin-top: 2*$utility-spacing;
}

.u-margin-top--3 {
  margin-top: 3*$utility-spacing;
}

.u-margin-bottom {
  margin-bottom: $utility-spacing;
}
.u-margin-bottom--2 {
  margin-bottom: 2*$utility-spacing;
}

.u-margin-bottom--3 {
  margin-bottom: 3*$utility-spacing;
}

.u-margin-bottom--6{
  margin-bottom: 6*$utility-spacing;

}


.u-margin-right {
  margin-right: $utility-spacing;
}

.u-margin-bottom {
  margin-bottom: $utility-spacing;
}

.u-margin-left {
  margin-left: $utility-spacing;
}

.u-padding {
  padding: $utility-spacing;
}

.u-padding-top {
  padding-top: $utility-spacing;
}

.u-padding-right {
  padding-right: $utility-spacing;
}

.u-padding-bottom {
  padding-bottom: $utility-spacing;
}

.u-padding-left {
  padding-left: $utility-spacing;
}

.u-breakout{
  //margin-left: -1rem;
  //margin-right: -1rem;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  // best for mobile browsers
}

.u-breakin{
  @media screen and (max-width: $bp-xlarge){
    margin-left: 1rem;
    margin-right: 1rem;
  }

}

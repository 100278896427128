/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  @media screen and (max-width: $bp-medium) {
    display: none;
  }
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin: 0 0 3rem 0;

  &.bottom{
      margin: 4rem 0 1rem 0;

    .c-main-nav__link {
      border-bottom: none;
      border-top: 1px solid $color-border;
    }
  }
}

.c-main-nav__item {
  font-weight: bold;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;

  &:hover .c-main-nav__dropdown {
    display: flex;
  }

}

.c-main-nav__link {
  border-bottom: 1px solid $color-border;
  display: block;
  padding: 0.2em 0.5em;
  text-decoration: none;
  text-align: center;

}

.current-menu-item .c-main-nav__link {
  //border-bottom-color: $color-black;
  color:$color-hover;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: $color-white;
  border-bottom: 1px solid $color-border;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: $color-black;
}

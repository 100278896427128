body.page-about{
  .about-section{
    font-size: 16px;
    margin-bottom: 4rem;
  }

  .locations{
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    span{
      font-weight: 500;
      color: white;
    }
    margin-bottom: 3rem;
  }

  .links-section{
    h3{
      font-size: 1.3rem;
    }
    text-align: center;
    margin-bottom: 5rem;
    .social-links{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin:-0.3rem;
      a{
        text-decoration: none;
        margin: 0.3rem;
      }
    }
  }

  .profile-img{
    max-width: 200px;
    display: block;
    margin: auto;
    border-radius: 50%;
    // border: solid 2px $color-light-grey;
    margin-bottom: 1rem;
  }

  .text{
    padding-left: 1.5rem;
    border-left: solid 1px $color-orange;
    margin-bottom: 3rem;
    h2{
      //text-align: center;
      font-size: 1.4rem;
      text-transform: none;
      color: $color-orange;
      margin-bottom: 1.5rem;
    }
  }

}
///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 2px;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'Poppins',
-apple-system,
  // Chrome < 56 for OS X (San Francisco)
BlinkMacSystemFont,
  // Windows
"Segoe UI",
  // Android
"Roboto",
  // Basic web fallback
"Helvetica Neue", Arial, sans-serif,
  // Emoji fonts
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" ;

/* Breakpoints
   ========================================================================== */

$bp-small: 480px;
$bp-medium: 768px;
$bp-large: 1024px;
$bp-xlarge: 1280px;
$bp-full: 1600px;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;
$color-almost-black: #1b1b1b;
$color-original-orange:#d83e10;
$color-test: #d83e10;
$color-orange: $color-test;

// Text
$color-bg: $color-almost-black;
$color-text: $color-very-light-grey;

// Links
$color-link: $color-medium-grey;
$color-hover: $color-orange;

// Borders
$color-border: $color-orange;

// Fills
$color-fill: $color-very-light-grey;


$pad-small: 10px;
$pad-medium: 20px;
$pad-large: 40px;


// http://www.luxiyalu.com/playground/overlay/

html{
  overflow-x: hidden;
}
html,body{
  height: 100%;
}


body.mobile-nav--opened{
  .body-content{

  }
}
.body-content{
  @media screen and (max-width: $bp-small){
    height: 100%; // causes additioanl scrollbars on desktop but required for mobile menu
    overflow: auto;
    overflow-x: hidden;
  }
  padding: 1rem;
}



/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-size: 2.25rem;
  margin-bottom: 3rem;

    @media screen and (max-width: $bp-small){
      font-size: 1.6rem;
    }
}

h2 {
  font-size: 1.75rem;
  margin-bottom: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

h1,h2,h3,h4,h5,h6{
  text-transform: uppercase;
}


.h1{
  @extend h1
}

.h2{
  @extend h2
}

.h3{
  @extend h3
}

.h4{
  @extend h4
}

.h5{
  @extend h5
}


.h6{
  @extend h6
}


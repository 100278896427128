/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: $bp-large;
}

.o-wrapper--big {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: $bp-xlarge;
}
.o-wrapper--small {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: 650px;
}


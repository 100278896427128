/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  font-weight: 600;
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.3rem 1.5rem;
  transition: $global-transition;
  border-radius: 1px;
  background: transparent;
  border: solid 1px white;
  text-transform: uppercase;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  border-color: $color-hover;

  //color:white;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-hover;
  }

  &:hover,
  &:focus {
    //font-weight: bold;
    color: $color-hover;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}


.c-btn-nav{
  border-color:$color-orange;
}
.c-btn-nav--left{
  text-align: left;
  border-width: 0 0 0 1px;
}

.c-btn-nav--right{
  text-align: right;

  border-width: 0 1px 0 0;

}
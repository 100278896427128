a.c-stealthy-link, a.instagram, a.vimeo, a.youtube, a.imdb{
   color:$color-text;

   &:hover{
        color:$color-orange;
   }
}

.social{
    a{
    margin:0.2rem;
    }
}




/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-out;
  //transition: color 0.1s;

  &:hover {
    color: $color-hover !important;

    h2{
      color:$color-hover !important;
    }
  }
}

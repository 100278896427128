body.home{
  h1{
    .divider{
      color: $color-orange;
      font-weight: 400;

    }
    .rest{
      font-weight: 400;
    }

    .freelance{
      @media screen and (max-width: $bp-small){
        display: none;
      }
    }
  }

}
.c-video-gallery{
  display: flex;
  margin: -1rem;
  margin-bottom: 2rem;


  flex-wrap: wrap;
  article{

    .thumbnail{
      //@extend .u-breakout;

    }
    a{
      h2{
        color: $color-very-light-grey;

      }
    }

    flex-basis: 50%;
    &.full{
      flex-basis: 100%;
    }
    @media screen and (max-width: $bp-small){
      flex-basis: 100%;

    }
    padding: 1rem;

    h2{
      margin-top: 0.3rem;
      transition: color 0.3s ease-out 0s;
      margin-bottom: 0;
    }
    h3{
      @media screen and (max-width: $bp-small){
        //display: none;
      }
      font-weight: 400;
      font-size: 1rem;

    }
  }
}
